import * as Sentry from '@sentry/browser';
import { config } from '../../config';

if (config.sentryToken) {
  // version and environment are defined in the webpack.define plugin
  const release = config.serviceVersion;
  const environment = import.meta.env.PROD ? 'production' : 'development';

  // should have been called before using it here
  // ideally before even rendering your react app
  Sentry.init({
    dsn: config.sentryToken,
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: Load failed',
      'TypeError: Cancelled',
      'TypeError: cancelled',
      'TypeError: cancelado',
      'TypeError: Abgebrochen',
      'TypeError: Zeitüberschreitung bei der Anforderung.',
      'TypeError: anulowane',
      'TypeError: annullato',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: visszavonva',
      'TypeError: отменено',
      'TypeError: annulé',
      'TypeError: geannuleerd',
      'Non-Error promise rejection captured',
      'No state in response',
      'Error: Frame window timed out',
      'Error: Network Error',
      'Error: expected accountId to be present but it was not',
      // Occurs when switching from admin-frontend to marketplace-frontend in the same browser profile
      /was called with missing 'accountId'/,
      // Can happen when the user's system clock is not yet synchronized, e.g. after daylight savings time change
      /Error: iat is in the future: /,
      'TypeError: error loading dynamically imported module',
      'Error: Called terms and condition for product:RIO-SKU00000051 tenant:EU',
      'Error: Redirect aborted',
      'Error: Error during REST call to https://api.marketplace.rio.cloud/free-trial: 401 . Response: ',
    ],
    environment,
    release,
  });
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const reportErrorToSentry = (...args: [any, any?]) => {
  Sentry.captureException(...args);
};
